export const emailTester =
  /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

export const nameRules = {
  validate: (value: string = '') => {
    if (!value?.trim()) return 'Please enter your name.';

    return true;
  },
};

export const emailRules = {
  validate: (value: string) => {
    return validateEmail(value) || 'Please enter a valid email address.';
  },
};

export const passwordRules = {
  validate: (value: string) => {
    if (value?.length < 6) {
      return 'Please use 6 characters or more.';
    }

    return true;
  },
};

export const validateEmail = (email: string) => {
  if (!email?.trim()) return false;

  const emailParts = email.split('@');

  if (emailParts.length !== 2) return false;

  const account = emailParts[0];
  const address = emailParts[1];

  if (account.length > 64) return false;

  if (address.length > 255) return false;

  const domainParts = address.split('.');
  if (domainParts.some((part) => part.length > 63)) return false;

  return emailTester.test(email);
};
