import React from 'react';
import styled from '@emotion/styled';
import { Modal, Typography } from 'antd';
import { Builder } from '@shared/types/builder';
import { RightOutlined } from '@ant-design/icons';
import { ModalProps } from 'antd/lib/modal/interface';
import { socialPlatformsIcons } from '@shared/ui/social-icons';
import { SocialLinksModalBody } from './social-links-modal-body';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const modalStyle: React.CSSProperties = {
  top: 20,
};

const modalStyles: ModalProps['styles'] = {
  body: {
    padding: 0,
    overflowY: 'auto',
    paddingRight: '2.4rem',
    maxHeight: 'calc(100vh - 200px)',
  },
  content: { paddingRight: 0 },
};

const iconProps: Partial<CustomIconComponentProps> = {
  style: { width: 32, height: 32 },
};

const Link = styled('a')(() => ({
  gap: '.8rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const LabelWrapper = styled('div')(() => ({
  gap: '.8rem',
  width: '100%',
  maxWidth: 300,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

export const SocialLinksModal: React.FC<{
  open: boolean;
  onCancel: () => void;
  socialLinks: Builder.CurrentMediakit.SocialLink[];
}> = ({ open, onCancel, socialLinks }) => (
  <Modal
    closable
    width={380}
    open={open}
    footer={null}
    destroyOnClose
    style={modalStyle}
    title="Social Links"
    onCancel={onCancel}
    styles={modalStyles}
  >
    <SocialLinksModalBody>
      {socialLinks.map((field, index) => {
        const item = socialPlatformsIcons[field.platform];
        if (!item) {
          return null;
        }

        if (field.platform === 23) {
          return (
            <Link
              key={index}
              rel="nofollow"
              target="_blank"
              href={`mailto:${field.value}`}
            >
              <LabelWrapper>
                <Typography.Text>
                  {socialPlatformsIcons[field.platform].dark(iconProps)}
                </Typography.Text>
                <Typography.Text ellipsis>{field.value}</Typography.Text>
              </LabelWrapper>
              <Typography.Text>
                <RightOutlined />
              </Typography.Text>
            </Link>
          );
        }

        if (field.platform === 25) {
          let value = field.value?.replace('https://t.me/', '');
          if (value?.startsWith('@')) {
            value = value.slice(1);
          }

          return (
            <Link
              key={index}
              rel="nofollow"
              target="_blank"
              href={`https://t.me/${value}`}
            >
              <LabelWrapper>
                <Typography.Text>
                  {socialPlatformsIcons[field.platform].dark(iconProps)}
                </Typography.Text>
                <Typography.Text ellipsis>{field.value}</Typography.Text>
              </LabelWrapper>
              <Typography.Text>
                <RightOutlined />
              </Typography.Text>
            </Link>
          );
        }

        if (field.platform === 26) {
          return (
            <Link
              key={index}
              rel="nofollow"
              target="_blank"
              href={`https://wa.me/${field.value}`}
            >
              <LabelWrapper>
                <Typography.Text>
                  {socialPlatformsIcons[field.platform].dark(iconProps)}
                </Typography.Text>
                <Typography.Text ellipsis>{field.value}</Typography.Text>
              </LabelWrapper>
              <Typography.Text>
                <RightOutlined />
              </Typography.Text>
            </Link>
          );
        }

        if (field.platform === 27) {
          return (
            <Link
              key={index}
              rel="nofollow"
              target="_blank"
              href={`https://discordapp.com/users/${field.value}`}
            >
              <LabelWrapper>
                <Typography.Text>
                  {socialPlatformsIcons[field.platform].dark(iconProps)}
                </Typography.Text>
                <Typography.Text ellipsis>{field.value}</Typography.Text>
              </LabelWrapper>
              <Typography.Text>
                <RightOutlined />
              </Typography.Text>
            </Link>
          );
        }

        if (field.platform === 29) {
          return (
            <Link
              key={index}
              rel="nofollow"
              target="_blank"
              href={`weixin://dl/chat?/${field.value}`}
            >
              <LabelWrapper>
                <Typography.Text>
                  {socialPlatformsIcons[field.platform].dark(iconProps)}
                </Typography.Text>
                <Typography.Text ellipsis>{field.value}</Typography.Text>
              </LabelWrapper>
              <Typography.Text>
                <RightOutlined />
              </Typography.Text>
            </Link>
          );
        }

        if (field.platform === 24 || field.platform === 28) {
          return (
            <Link
              key={index}
              rel="nofollow"
              target="_blank"
              href={`tel:${field.value}`}
            >
              <LabelWrapper>
                <Typography.Text>
                  {socialPlatformsIcons[field.platform].dark(iconProps)}
                </Typography.Text>
                <Typography.Text ellipsis>{field.value}</Typography.Text>
              </LabelWrapper>
              <Typography.Text>
                <RightOutlined />
              </Typography.Text>
            </Link>
          );
        }

        return (
          <Link key={index} rel="nofollow" target="_blank" href={field.value}>
            <LabelWrapper>
              <Typography.Text>{item.dark(iconProps)}</Typography.Text>
              <Typography.Text ellipsis>{field.value}</Typography.Text>
            </LabelWrapper>
            <Typography.Text>
              <RightOutlined />
            </Typography.Text>
          </Link>
        );
      })}
    </SocialLinksModalBody>
  </Modal>
);
